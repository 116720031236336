import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion'; // For animations
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'; // For menu icons
import { Link } from 'react-router-dom'; // Import Link for routing

const HeaderPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleLinkClick = () => {
    setIsOpen(false); // Close the menu when a link is clicked
  };

  return (
    <div>
      {/* Header */}
      <header className="bg-gray-900 text-white shadow-lg">
        <div className="max-w-7xl mx-auto px-1 sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between h-16">
            {/* Mobile menu button */}
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              <button
                type="button"
                className="p-2 rounded-md text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                onClick={() => setIsOpen(!isOpen)}
              >
                <span className="sr-only">{isOpen ? 'Close menu' : 'Open menu'}</span>
                {isOpen ? (
                  <XMarkIcon className="h-8 w-8" aria-hidden="true" />
                ) : (
                  <Bars3Icon className="h-8 w-8" aria-hidden="true" />
                )}
              </button>
            </div>
            {/* Logo */}
            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex-shrink-0">
                <Link to="/" className="text-white text-2xl font-semibold no-underline hover:text-gray-400 transition-colors duration-300">
                  Kashmir Adventure
                </Link>
              </div>
            </div>
            {/* Desktop Navigation */}
            <div className="hidden sm:flex sm:items-center sm:space-x-8">
              <Link to="/" className="text-white text-lg py-2 px-4 no-underline hover:bg-gray-700 rounded-md transition-colors duration-300">Home</Link>
              <Link to="/about" className="text-white text-lg py-2 px-4 no-underline hover:bg-gray-700 rounded-md transition-colors duration-300">About</Link>
              <Link to="/packages" className="text-white text-lg py-2 px-4 no-underline hover:bg-gray-700 rounded-md transition-colors duration-300">Packages</Link>
              <Link to="/contact" className="text-white text-lg py-2 px-4 no-underline hover:bg-gray-700 rounded-md transition-colors duration-300">Contact</Link>
            </div>
          </div>
        </div>
      </header>

      {/* Side Menu */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="fixed inset-0 bg-gray-900 bg-opacity-90 z-50 sm:hidden"
            initial={{ opacity: 0, x: '-100%' }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: '-100%' }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          >
            <div className="absolute top-0 right-0 p-6">
              <button
                type="button"
                className="text-white"
                onClick={() => setIsOpen(false)}
              >
                <XMarkIcon className="h-8 w-8" aria-hidden="true" />
              </button>
            </div>
            <div className="flex flex-col items-center mt-20 space-y-6">
              <Link to="/" className="text-white text-xl py-3 px-4 no-underline hover:bg-gray-700 rounded-md transition-colors duration-300" onClick={handleLinkClick}>Home</Link>
              <Link to="/about" className="text-white text-xl py-3 px-4 no-underline hover:bg-gray-700 rounded-md transition-colors duration-300" onClick={handleLinkClick}>About</Link>
              <Link to="/packages" className="text-white text-xl py-3 px-4 no-underline hover:bg-gray-700 rounded-md transition-colors duration-300" onClick={handleLinkClick}>Packages</Link>
              <Link to="/contact" className="text-white text-xl py-3 px-4 no-underline hover:bg-gray-700 rounded-md transition-colors duration-300" onClick={handleLinkClick}>Contact</Link>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default HeaderPage;
