import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './BookingPage.css'; // Ensure this path is correct

const BookingSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string().required('Phone number is required'),
});

const BookingPage = () => {
  const { id } = useParams();
  const [packageName, setPackageName] = useState('');
  const [loading, setLoading] = useState(true);
  const [bookingStatus, setBookingStatus] = useState('');
  const [isBookingAllowed, setIsBookingAllowed] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);

    axios.get(`https://tour.tourismofkashmir.com/get_packages.php`)
      .then(response => {
        const pkg = response.data.find(pkg => pkg.id === id);
        if (pkg) {
          setPackageName(pkg.name);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching package details', error);
        setLoading(false);
      });
  }, [id]);

  const checkBookingStatus = (values) => {
    axios.get(`https://tour.tourismofkashmir.com/apibooking.php?id=${id}`, {
      params: { email: values.email, phone: values.phone }
    })
    .then(response => {
      if (response.data.error) {
        setBookingStatus(response.data.error);
        setIsBookingAllowed(false);
      } else {
        setBookingStatus('');
        setIsBookingAllowed(true);
      }
    })
    .catch(error => {
      console.error('Error checking booking status', error);
    });
  };

  const handleSubmit = (values, { setSubmitting }) => {
    axios.post(`https://tour.tourismofkashmir.com/apibooking.php?id=${id}`, values)
      .then(response => {
        if (response.data.error) {
          toast.error(response.data.error);
        } else {
          toast.success('Booking successful!');
        }
        setBookingStatus(response.data.error || '');
      })
      .catch(error => {
        console.error('Error making booking', error);
        toast.error('Error making booking. Please try again.');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (loading) {
    return (
      <div className="container">
        <h1>Book Your Tour</h1>
        <div className="skeleton-wrapper">
          <Skeleton count={1} height={50} className="mb-4" />
          <Skeleton count={4} height={40} className="mb-4" />
          <Skeleton count={1} height={50} />
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <h1>Book Your Tour</h1>
      <Formik
        initialValues={{ name: '', email: '', phone: '' }}
        validationSchema={BookingSchema}
        onSubmit={(values, actions) => {
          checkBookingStatus(values);
          if (isBookingAllowed) {
            handleSubmit(values, actions);
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className="formik-form">
            <div>
              <label htmlFor="packageName" className="block text-lg font-semibold">Package Name</label>
              <Field
                id="packageName"
                name="packageName"
                type="text"
                value={packageName}
                readOnly
                className="input-field"
              />
            </div>

            <div>
              <label htmlFor="name" className="block text-lg font-semibold">Name</label>
              <Field
                id="name"
                name="name"
                type="text"
                className="input-field"
              />
              <ErrorMessage name="name" component="div" className="text-red-500" />
            </div>

            <div>
              <label htmlFor="email" className="block text-lg font-semibold">Email</label>
              <Field
                id="email"
                name="email"
                type="email"
                className="input-field"
              />
              <ErrorMessage name="email" component="div" className="text-red-500" />
            </div>

            <div>
              <label htmlFor="phone" className="block text-lg font-semibold">Phone</label>
              <Field
                id="phone"
                name="phone"
                type="text"
                className="input-field"
              />
              <ErrorMessage name="phone" component="div" className="text-red-500" />
            </div>

            {bookingStatus && (
              <div className="text-red-500 font-semibold mt-4">{bookingStatus}</div>
            )}

            <button
              type="submit"
              className="submit-button"
              disabled={isSubmitting || !isBookingAllowed}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </Form>
        )}
      </Formik>
      <ToastContainer />
    </div>
  );
};

export default BookingPage;
