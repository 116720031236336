import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './TourPackagesPage.css'; // Import the updated CSS file

const TourPackagesPage = () => {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get('https://tour.tourismofkashmir.com/get_packages.php')
      .then(response => {
        setPackages(response.data);
        setLoading(false);
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });

    // Scroll to top when component mounts
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  if (loading) {
    return (
      <div className="container">
        <h1 className="page-title">Tour Packages</h1>
        <div className="grid">
          {Array.from({ length: 6 }).map((_, index) => (
            <div key={index} className="card">
              <Skeleton height={200} className="card-image" />
              <div className="card-body">
                <Skeleton height={20} className="card-title" />
                <Skeleton height={16} count={2} className="card-description" />
                <Skeleton width={100} height={20} className="card-price" />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (error) return <div className="error-message">Error fetching data: {error.message}</div>;

  return (
    <div className="container">
      {/* Breadcrumbs */}
      <nav className="breadcrumbs">
        <ol>
          <li>
            <Link to="/" className="breadcrumb-link">Home</Link>
          </li>
          <li>
            <span className="breadcrumb-separator">/</span>
          </li>
          <li>
            <span className="breadcrumb-current">Tour Packages</span>
          </li>
        </ol>
      </nav>

      <h1 className="page-title">Tour Packages</h1>
      <div className="grid">
        {packages.map(pkg => (
          <div key={pkg.id} className="card">
            <Link to={`/package/${pkg.slug}`} className="card-link">
              <img
                src={pkg.image_url}
                alt={pkg.name}
                className="card-vns-image"
              />
              <div className="card-body">
                <h2 className="card-title">{pkg.name}</h2>
                <p className="card-description">{pkg.description}</p>
                <p className="card-price">Price: ${pkg.price}</p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TourPackagesPage;
