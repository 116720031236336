// components/Loader.js
import React from 'react';
import './Loader.css';

const Loader = () => {
  return (
    <div className="loader">
      <img src="https://leaknews.net/kOnzy.gif" alt="Loading..." />
    </div>
  );
};

export default Loader;
