import React, { useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HomePage = () => {
  const [videos] = useState([
    "https://tour.tourismofkashmir.com/videos/video1.mp4",
    "https://tour.tourismofkashmir.com/videos/video2.mp4",
    "https://tour.tourismofkashmir.com/videos/video3.mp4",
    "https://tour.tourismofkashmir.com/videos/video4.mp4",
  ]);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: false,
    pauseOnHover: true,
    cssEase: "ease-in-out",
  };

  const cardData = [
    {
      id: 1,
      title: "Experience Tranquility",
      description: "Discover serene landscapes and majestic views.",
      image:
        "https://tour.tourismofkashmir.com/images/premium_photo-1661902264812-77019190be30.avif",
    },
    {
      id: 2,
      title: "Adventure Awaits",
      description: "Embark on a journey through the breathtaking valleys.",
      image:
        "https://tour.tourismofkashmir.com/images/istockphoto-1044781846-2048x2048.jpg",
    },
    {
      id: 3,
      title: "Unforgettable Moments",
      description: "Capture memories amidst the stunning scenery.",
      image:
        "https://tour.tourismofkashmir.com/images/istockphoto-532959840-2048x2048.jpg",
    },
  ];

  const reviewsData = [
    {
      id: 1,
      text: "“Our trip to Kashmir was unforgettable! The tour was well-organized, and the views were breathtaking. Highly recommend!”",
      author: "John Doe",
    },
    {
      id: 2,
      text: "“Excellent service and a fantastic experience. The guides were knowledgeable and friendly. Thank you for a memorable vacation!”",
      author: "Jane Smith",
    },
    {
      id: 3,
      text: "“The landscapes were breathtaking and the service was top-notch. We had an amazing time!”",
      author: "Michael Lee",
    },
  ];

  const faqData = [
    {
      id: 1,
      question: "What is the best time to visit Kashmir?",
      answer:
        "The best time to visit Kashmir is between April and October when the weather is pleasant and suitable for sightseeing and outdoor activities.",
    },
    {
      id: 2,
      question: "Do you offer customized tour packages?",
      answer:
        "Yes, we offer customized tour packages based on your preferences and requirements. Contact us to discuss your needs.",
    },
  ];

  const [form, setForm] = useState({ name: "", email: "", message: "" });
  const [activeAccordion, setActiveAccordion] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Message sent!");
    // Implement form submission logic here
  };

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const { ref: sliderRef, inView: sliderInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: formRef, inView: formInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: faqRef, inView: faqInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: reviewsRef, inView: reviewsInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: videosRef, inView: videosInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div className="bg-gray-100">
      {/* Slider Section */}
         {/* Slider Section */}
         <motion.section
        className="mb-16"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: sliderInView ? 1 : 0, y: sliderInView ? 0 : 20 }}
        transition={{ duration: 1.2, ease: 'easeOut' }}
        ref={sliderRef}
      >
        <Slider {...sliderSettings} className="relative">
          {cardData.map(card => (
            <div key={card.id} className="relative">
              <img
                src={card.image}
                alt={card.title}
                className="w-full h-[420px] md:h-[600px] lg:h-[700px] object-cover"
              />
              <div className="absolute bottom-0 left-0 right-0 p-4 bg-gray-100 bg-opacity-90 text-center">
                <h2 className="text-xl font-bold text-gray-800">{card.title}</h2>
                <p className="text-gray-700">{card.description}</p>
              </div>
            </div>
          ))}
        </Slider>
      </motion.section>

      {/* Header Section */}
      <motion.header
        className="text-center mb-16"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: sliderInView ? 1 : 0, y: sliderInView ? 0 : 20 }}
        transition={{ duration: 1.2, ease: "easeOut" }}
      >
        <p className="text-lg text-gray-600">
          Your adventure to the enchanting land of Kashmir starts here.
        </p>
      </motion.header>

      {/* Explore Packages Button */}
      <motion.section
        className="text-center mb-16 px-4"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: formInView ? 1 : 0, y: formInView ? 0 : 20 }}
        transition={{ duration: 2.2, ease: "easeOut" }}
        ref={formRef}
      >
        <Link
          to="/packages"
          className="inline-block no-underline bg-blue-500 text-white py-3 px-8 rounded-full text-lg font-semibold shadow-md transition duration-300 hover:bg-blue-600 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
        >
          Explore Packages
        </Link>
      </motion.section>

      {/* Contact Form Section */}
      <motion.section
        className="text-center mb-16 px-4"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: formInView ? 1 : 0, y: formInView ? 0 : 20 }}
        transition={{ duration: 1.2, ease: "easeOut" }}
        ref={formRef}
      >
        <h2 className="text-3xl md:text-4xl font-extrabold mb-8 text-gray-900">
          Contact Us
        </h2>
        <motion.form
          onSubmit={handleSubmit}
          className="mx-auto max-w-2xl p-8 bg-white bg-opacity-50 shadow-lg rounded-lg space-y-6 backdrop-filter backdrop-blur-lg"
          initial={{ opacity: 0 }}
          animate={{ opacity: formInView ? 1 : 0 }}
          transition={{ duration: 1.2, ease: "easeOut" }}
        >
          <div>
            <label
              htmlFor="name"
              className="block text-left text-gray-800 font-semibold mb-1"
            >
              Name:
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={form.name}
              onChange={handleInputChange}
              className="w-full px-4 py-3 border-b-2 border-gray-300 bg-transparent placeholder-gray-500 focus:border-blue-500 focus:outline-none transition duration-300 ease-in-out"
              placeholder="Enter your name"
              required
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-left text-gray-800 font-semibold mb-1"
            >
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={form.email}
              onChange={handleInputChange}
              className="w-full px-4 py-3 border-b-2 border-gray-300 bg-transparent placeholder-gray-500 focus:border-blue-500 focus:outline-none transition duration-300 ease-in-out"
              placeholder="Enter your email"
              required
            />
          </div>
          <div>
            <label
              htmlFor="message"
              className="block text-left text-gray-800 font-semibold mb-1"
            >
              Message:
            </label>
            <textarea
              id="message"
              name="message"
              value={form.message}
              onChange={handleInputChange}
              className="w-full px-4 py-3 border-b-2 border-gray-300 bg-transparent placeholder-gray-500 focus:border-blue-500 focus:outline-none transition duration-300 ease-in-out"
              rows="5"
              placeholder="Enter your message"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-3 rounded-full font-semibold shadow-md hover:bg-blue-600 transition duration-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
          >
            Send Message
          </button>
        </motion.form>
      </motion.section>

      {/* Video Section */}
      <motion.section
        className="mb-16 px-2.5 lg:px-16"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: videosInView ? 1 : 0, y: videosInView ? 0 : 20 }}
        transition={{ duration: 1.5, ease: "easeOut" }}
        ref={videosRef}
      >
        <h2 className="text-center text-3xl font-bold mb-8 text-gray-800">
          Travel Videos
        </h2>
        <div className="flex overflow-x-auto space-x-4 pb-4 snap-x snap-mandatory scrollbar-hide">
          {videos.map((video, index) => (
            <div
              key={index}
              className="flex-shrink-0 w-[320px] h-[180px] bg-gray-800 rounded-lg shadow-lg overflow-hidden relative group"
            >
              <video
                className="w-full h-full object-cover rounded-lg transition-transform duration-300 group-hover:scale-105"
                src={video}
                autoPlay
                muted
                loop
                playsInline
              ></video>
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-12 h-12 text-white"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polygon points="5 3 19 12 5 21 5 3" />
                </svg>
              </div>
            </div>
          ))}
        </div>
      </motion.section>

      {/* FAQ Section */}
      <motion.section
        className="mb-16"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: faqInView ? 1 : 0, y: faqInView ? 0 : 20 }}
        transition={{ duration: 2.2, ease: "easeOut" }}
        ref={faqRef}
      >
        <h2 className="text-center text-3xl font-bold mb-8 text-gray-800">
          Frequently Asked Questions
        </h2>
        <div className="max-w-2xl mx-auto space-y-4">
          {faqData.map((item, index) => (
            <motion.div
              key={item.id}
              className={`border-2 rounded-lg overflow-hidden transition-shadow duration-300 ${
                activeAccordion === index ? "shadow-lg" : "shadow-sm"
              }`}
              onClick={() => toggleAccordion(index)}
            >
              <div className="p-4 cursor-pointer bg-white hover:bg-gray-50 transition duration-300">
                <div className="flex justify-between items-center">
                  <h3 className="text-xl font-semibold text-gray-800">
                    {item.question}
                  </h3>
                  <span className="text-xl text-gray-600">
                    {activeAccordion === index ? (
                      <AiOutlineMinus />
                    ) : (
                      <AiOutlinePlus />
                    )}
                  </span>
                </div>
              </div>
              {activeAccordion === index && (
                <div className="p-4 bg-gray-50 text-gray-700">
                  <p>{item.answer}</p>
                </div>
              )}
            </motion.div>
          ))}
        </div>
      </motion.section>

      {/* Reviews Section */}
      <motion.section
        className="mb-16"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: reviewsInView ? 1 : 0, y: reviewsInView ? 0 : 20 }}
        transition={{ duration: 2.2, ease: "easeOut" }}
        ref={reviewsRef}
      >
        <h2 className="text-center text-3xl font-bold mb-8 text-gray-800">
          What Our Clients Say
        </h2>
        <div className="flex flex-wrap justify-center gap-8">
          {reviewsData.map((review) => (
            <div
              key={review.id}
              className="max-w-xs p-6 bg-white shadow-lg rounded-lg text-center transform transition duration-300 hover:scale-105"
            >
              <p className="text-gray-700 italic">"{review.text}"</p>
              <p className="mt-4 font-semibold text-gray-800">
                - {review.author}
              </p>
            </div>
          ))}
        </div>
      </motion.section>
    </div>
  );
};

export default HomePage;

