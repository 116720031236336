import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './TourPackageDetailsPage.css'; // Import the updated CSS file

const TourPackageDetailsPage = () => {
  const { slug } = useParams();
  const [packageDetails, setPackageDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!slug) {
      setError(new Error("Slug parameter is missing"));
      setLoading(false);
      return;
    }

    axios.get('https://tour.tourismofkashmir.com/get_packages.php')
      .then(response => {
        console.log('API Response:', response.data); // Debugging line
        const pkg = response.data.find(pkg => pkg.slug && pkg.slug.trim().toLowerCase() === slug.trim().toLowerCase());
        console.log('Package found:', pkg); // Debugging line
        setPackageDetails(pkg);
        setLoading(false);
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });
  }, [slug]);

  if (loading) return (
    <div className="container">
      <div className="card">
        <Skeleton height={300} className="card-image" />
        <div className="card-body">
          <Skeleton height={40} width="60%" className="mb-4" />
          <Skeleton count={3} height={24} className="mb-4" />
          <Skeleton height={24} width="40%" className="mb-4" />
          <Skeleton height={48} className="mb-6" />
          <Skeleton height={48} width="40%" />
        </div>
      </div>
    </div>
  );

  if (error) return <div className="error-message">Error fetching data: {error.message}</div>;
  if (!packageDetails) return <div className="error-message">Package not found</div>;

  return (
    <div className="container">
      {/* Breadcrumbs */}
      <nav className="breadcrumbs">
        <ol>
          <li>
            <Link to="/" className="breadcrumb-link">Home</Link>
          </li>
          <li>
            <span className="breadcrumb-separator">/</span>
          </li>
          <li>
            <Link to="/packages" className="breadcrumb-link">Tour Packages</Link>
          </li>
          <li>
            <span className="breadcrumb-separator">/</span>
          </li>
          <li>
            <span className="breadcrumb-current">{packageDetails.name}</span>
          </li>
        </ol>
      </nav>

      <div className="card">
        {packageDetails.image_url && (
          <img
            src={packageDetails.image_url}
            alt={packageDetails.name}
            className="cardss-vn-image"
          />
        )}
        <div className="card-body">
          <h1 className="card-title">{packageDetails.name}</h1>
          <p className="card-description">{packageDetails.description}</p>
          <div className="price-section">
            <p className="card-price">Price: <span>${packageDetails.price}</span></p>
          </div>
          <div className="details-section">
            <p><strong>Hotel:</strong> {packageDetails.hotel || 'Not Available'}</p>
            <p><strong>Car:</strong> {packageDetails.car || 'Not Available'}</p>
            <p><strong>Meal Plan:</strong> {packageDetails.meal_plan || 'Not Available'}</p>
            <p><strong>Activities:</strong> {packageDetails.activities || 'Not Available'}</p>
          </div>
          <Link to={`/book/${packageDetails.id}`} className="book-now-button">Book Now</Link>
        </div>
      </div>
    </div>
  );
};

export default TourPackageDetailsPage;
