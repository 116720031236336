import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import TourPackagesPage from './components/TourPackagesPage';
import TourPackageDetailsPage from './components/TourPackageDetailsPage';
import BookingPage from './components/BookingPage';
import HeaderPage from './components/HeaderPage';
import LoaderManager from './components/LoaderManager';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <HeaderPage />
      <LoaderManager>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/packages" element={<TourPackagesPage />} />
          <Route path="/package/:slug" element={<TourPackageDetailsPage />} />
          <Route path="/book/:id" element={<BookingPage />} />
        </Routes>
      </LoaderManager>
    </Router>
  );
}

export default App;
