// components/LoaderManager.js
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Loader from './Loader';

const LoaderManager = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Start loading on route change
    setLoading(true);

    // Simulate loading time (e.g., fetching data)
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500); // Adjust timing as needed

    return () => clearTimeout(timer); // Cleanup on unmount
  }, [location]);

  // Don't show loader on the homepage
  const shouldShowLoader = location.pathname !== '/';

  return (
    <>
      {shouldShowLoader && loading && <Loader />}
      {children}
    </>
  );
};

export default LoaderManager;
